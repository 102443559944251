import { Component, Input, OnInit } from '@angular/core';
import { Product } from './product.interface';
import { UserService } from 'src/app/services/user.service';
import { ProductsService } from 'src/app/services/products.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-product-v2',
  templateUrl: './product-v2.component.html',
  styleUrls: ['./product-v2.component.scss']
})
export class ProductV2Component implements OnInit {

  @Input() product: Product;
  urlImg = '';
  sessionLang = '';
  seller = '';

  constructor(private _user: UserService, private _products: ProductsService, private _lang: LangService) { }

  ngOnInit() {
    const [ img ] = this.product.pictures;
    this.urlImg = img.url;
    this.seller = this.product.seller.company
    this.sessionLang = this._lang.getLanguage('edm_lang');
  }

  addFavorite(id: any) {
    this._user.addFavorite(id).subscribe((uss: any) => {
      console.log(uss);
    })
  }

  removeFavorite(id: any) {
    this._user.removeFavorite(id).subscribe((res: any) => {
    });
  }

  openProductInNewTab(productId:string){
    window.open(`/products/id/${productId}`)
  }

}
